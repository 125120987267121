<template>
    <div class="m-wrapper04">
        <div class="m-doctor-info02">
            <div class="doctor-info-con04">
                <div class="doctor-name-con01">
                    <div class="doctor-name-box01">
                        <span class="doctor-name04">{{doctorInfo.doctorName}}</span>
                        <span class="doctor-type02">{{doctorInfo.lczc}}</span>
                    </div>
                    <div class="doctro-name-box02">
                        <span>{{hosName}}</span>
                        <span>{{depName}}</span>
                    </div>
                </div>
                <img :src="doctorInfo.pictureUrl || defaultAvadar" class="doctor-avatar03">
            </div>
            <div class="doctor-info-con05">
                <div class="doctor-info-box03">
                    <div class="doctor-info-tit01">
                        <img src="../../assets/images/icon-scjb.png" class="doctor-info-icon01">
                        <span class="f-fl">擅长疾病</span>
                    </div>
                    <p>{{doctorInfo.skill}}</p>
                </div>
                <div class="doctor-info-box03">
                    <div class="doctor-info-tit01">
                        <img src="../../assets/images/icon-grjj.png" class="doctor-info-icon01">
                        <span class="f-fl">个人简介</span>
                    </div>
                    <p>
                        {{doctorInfo.intro}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
    data() {
        return {
            defaultAvadar: require('../../assets/images/avatar-2.png'),
            doctorId: '',
            depName: '',
            hosName: ''
        }
    },
    computed: {
        ...mapState({
            doctorInfo: state => state.appointmentInfo.doctorInfo
        })
    },
    mounted() {
        this.hosName = this.$route.query['hosName']
        this.depName = this.$route.query['depName']
        // 获取地址栏传递过来的医生的ID
        this.doctorSn = this.$route.query['doctorSn']
        // 获取医生的信息
        this.$store.dispatch('queryDoctorInfo', {
            doctorSn: this.doctorSn
        }).then(() => {
            console.log('doctorInfo,', this.doctorInfo)
        })
    }
}
</script>
<style lang="scss" scoped>
.m-wrapper04 {
    text-align: left;
    background: #fff;
}
</style>